.homepage .header {
  position: fixed;
  z-index: 99;
  background-color: white;
  color: var(--TRIB-Blue-Primary);
  padding: 20px 10px;
  height: 80px;
  width: 100%;
  border-bottom: 2px solid var(--TRIB-Blue-Primary);
  filter: hue-rotate(var(--hue_rotation)) contrast(var(--contrast_value));
}
.homepage .topbar {
  display: flex;
  justify-content: space-between;
}
.homepage .header .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: var(--TRIB-Green-Primary);
}
.homepage .header .navigation {
  display: flex;
  max-width: 500px;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.homepage .header .navigation > a {
  padding: 10px;
  cursor: pointer;
  color: var(--TRIB-Blue-Primary);
}
.homepage .header .navigation button {
  border: none;
  background: none;
  cursor: pointer;
}
.homepage .header .navigation img {
  height: 30px;
}

.homepage .header .navigation button:hover {
  filter: invert(1);
}
.homepage .header .modal {
  padding: 50px;
}
.homepage .header .modal button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px white;
}

.homepage .header .modal button:hover {
  border: solid 2px black;
}
.homepage .header .modal button > i {
  margin: 0;
}
