.homepage {
  overflow-x: hidden;
}
.homepage .borderedContent {
  position: relative;
  max-width: 1320px;
  margin: 0 auto;
  overflow-x: hidden;
}
.homepage .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  filter: hue-rotate(var(--hue_rotation)) contrast(var(--contrast_value));
}

.homepage .about {
  width: 100%;
}
.homepage .about p {
  max-width: 1200px;
}
.homepage .about ul {
  max-width: 1200px;
}

.homepage .section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
.homepage .section:first-child {
  margin-top: 80px;
}

.homepage .projects {
  max-width: 1200px;
}
.homepage .projectList {
  max-height: 700px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.homepage .project {
  width: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px var(--TRIB-Blue-Primary);
  border-radius: 20px;
  color: black;
  background-color: white;
  gap: 20px;
  padding: 2%;
  transition: all 0.2s ease-in-out;
  margin: 0 2%;
}
@media (max-width: 500px) {
  .homepage .project {
    flex-direction: column;
  }
}

.homepage .project:hover {
  width: 96%;
  padding: 3%;
  box-shadow: 0 0 5px var(--TRIB-Blue-Primary);
  cursor: pointer;
}
.homepage .project img {
  width: 200px;
  object-fit: contain;
  border-radius: 20px;
  border: solid 2px var(--TRIB-Green-Primary);
}

.homepage .members .logos {
  max-width: 1200px;
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
}
.homepage .members .logos .member {
  object-fit: contain;
  max-width: 240px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.homepage .footer {
  width: 100%;
  min-height: 200px;
  background-color: var(--TRIB-Green-Primary);
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.homepage .footer > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.homepage .footer a {
  color: white !important;
}

.homepage .h1 {
  font-size: calc(32px * var(--relative_font_size));
  font-weight: 700;
}
.homepage .h2 {
  font-size: calc(26px * var(--relative_font_size));
  font-weight: 600;
  color: white;
  background-color: var(--TRIB-Green-Primary);
  width: 100vw;
  text-align: center;
  padding: 10px;
  margin-top: 0;
  margin-bottom: 50px;
}
.homepage .h3 {
  font-size: 22px;
  font-weight: 500;
}
.homepage .h4 {
  font-size: 20px;
  font-weight: 400;
}
.homepage .h5 {
  font-size: 16px;
  font-weight: 300;
}
.homepage .link {
  color: blue;
}
