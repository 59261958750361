:root {
  --relative_font_size: 1;
  --relative_line_height: 1;
  --hue_rotation: 0deg;
  --contrast_value: 1;
}

body {
  --TRIB-Green-Primary: #21808b;
  --TRIB-Blue-Primary: #15335e;
  --TRIB-Green-Variation-1: #bff7ea;
  --TRIB-Green-Variation-2: #77dccc;
  --TRIB-Green-Variation-3: #32a4a0;
  --TRIB-Green-Variation-4: #14586b;
  --TRIB-Green-Variation-5: #0d3d59;
  --TRIB-Blue-Variation-1: #dbf2ff;
  --TRIB-Blue-Variation-2: #96dcfd;
  --TRIB-Blue-Variation-3: #43b3ea;
  --TRIB-Blue-Variation-4: #197cbe;
  --TRIB-Blue-Variation-5: #16477f;

  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(11pt * var(--relative_font_size));
  line-height: calc(100% * var(--relative_line_height));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
